@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Satoshi", sans-serif;
}

h4 {
  font-weight: 800;
}

.animation {
  animation: animate 0.5s ease-in-out;
}

@keyframes animate {
  from {
    opacity: 0;
    transform: translateX(3rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
